.navbar {

  .logo {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    img {
      height: 100%;
    }
  }

  .title {
    padding: 8px;
    color: white;
    font-weight: 700;
    font-size: 18px;
    margin-left: 30px;
  }

  .prod {
    background-color: red;
  }

  .qa {
    background-color: orange;
  }

  .dev {
    background-color: green;
  }
}