.editor {
  outline: none;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  .header {
    display: flex;
    align-items: center;

    h1 {
      margin-right: auto;
      margin-top: 0;
    }
  }
}

.no_settings_warning {
  font-weight: bolder;
  padding: 20px;
  border: 3px solid red;
}

.fixed_container {
  min-height: 300px;
}

.max_height_container {
  max-height: 300px;
  overflow-y: scroll;
  padding: 5px;
}

.max_height_content {
  overflow: hidden;
}

.fixed_content {
  position: fixed !important;
  bottom: 10px;
  right: 30px;
  background-color: rgba(211, 211, 211, 0.1);
}

.data_content {
  display: flex;
  justify-content: center;
  border-radius: 0.25em;
  margin-bottom: 1rem;
}