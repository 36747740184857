.spinner {
  padding-top: 300px;
}
.submenu {
 
  .clientLabel {
    display: flex;
    align-items: center;
    
    .settingsIcon {
      display: none;
      margin-left: auto;
    }
  }

  &:hover {
    background-color: #f5f5f5f5;

    .settingsIcon {
      display: flex;
    }
  }

  :has(.settingsIcon :global(.active)) {
    font-weight: bold;
 }
}